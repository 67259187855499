<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.primario" dark dense>
      <v-toolbar-title>DATOS DE LA PQR</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.ptorecepcion_id"  :loading="loadingForm" :items="items.items_puntos_recepcion" item-text="descripcion" item-value="id" :color="colores.secundario" label='PUNTO DE RECEPCIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
            <texto2 v-if="registro.codigo_sfc" :colores="colores" :titulo="'SFC'" :descripcion="registro.codigo_sfc"></texto2>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'RETROALIMENTACION'" :obj="registro.retroalimentacion"></texto3>
        </v-col>
        <v-col cols="12" md="6">
          <texto3 :colores="colores" :titulo="'MACROMITVO'" :obj="registro.macromotivo"></texto3>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.tipologia_id" :rules="[rules.requerido]" :items="items_tipologias" item-text="descripcion" item-value="id" :color="colores.secundario" :loading="loadingTipologia" label='* TIPOLOGIA' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.sucursal_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_sucursales" item-text="descripcion" item-value="id" :color="colores.secundario" label='* SUCURSAL' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.proceso_id" :rules="[rules.requerido]" :loading="loadingForm" :items="items.items_procesos" item-text="descripcion" item-value="id" :color="colores.secundario" label='* PROCESO' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'RAMO'" :obj="registro.ramo"></texto3>
        </v-col>
      </v-row>
      <!--campos nuevos-->
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'TUTELA'" :obj="registro.tutela"></texto3>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.entecontrol_id"  :loading="loadingForm" :items="items.items_ente_control" item-text="descripcion" item-value="id" :color="colores.secundario" label='ENTE DE CONTROL' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.replica_id" v-on:input="input_replica(gestion.replica_id)" :loading="loadingForm" :items="items.items_replica" item-text="descripcion" item-value="id" :color="colores.secundario" label='RÉPLICA' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1" v-if="gestion.replica_id === 250">
          <v-text-field v-model="gestion.argumento_replica" label='ARGUMENTO RÉPLICA' autocomplete="off" :color="colores.secundario" maxlength="256" counter="256"></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.desistimiento_id" :loading="loadingForm" :items="items.items_desistimientos" item-text="descripcion" item-value="id" :color="colores.secundario" label='DESISTIMIENTO' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.quejaexpress_id" :loading="loadingForm" :items="items.items_queja_express" item-text="descripcion" item-value="id" :color="colores.secundario" label='QUEJA EXPRÉS' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'INSTANCIA DE RECEPCIÓN'" :obj="registro.instancia"></texto3>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <texto3 :colores="colores" :titulo="'CANAL'" :obj="registro.canal"></texto3>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.admision_id"  :loading="loadingForm" :items="items.items_admision" item-text="descripcion" item-value="id" :color="colores.secundario" label='ADMISIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.productodigital_id"  :loading="loadingForm" :items="items.items_producto_digital" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRODUCTO DIGITAL' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete v-model="gestion.aceptacion_id"  :loading="loadingForm" :items="items.items_aceptacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='ACEPTACIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-autocomplete v-model="gestion.rectificacion_id"  :loading="loadingForm" :items="items.items_rectificacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='RECTIFICACÓN' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.prorroga_id"  :loading="loadingForm" :items="items.items_prorroga" item-text="descripcion" item-value="id" :color="colores.secundario" label='PRÓRROGA' autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete v-model="gestion.marcacion_id"  :loading="loadingForm" :items="items.items_marcacion" item-text="descripcion" item-value="id" :color="colores.secundario" label='MARCACIÓN' autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12">
            <v-card-text style="white-space:pre-wrap;" class="pa-0">
                <div >{{ registro.descripcion }}</div>
            </v-card-text>
          </v-col>
      </v-row>
      <v-list dense>
        <div v-for="(item, index) in registro.gesarchivos" :key="index">
          <verArchivos :colores="colores" :tipo="'GESTION'" :item="item"></verArchivos>
          <v-divider></v-divider>
        </div>
      </v-list>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import recListados from '@/js/rec_listados.js'
export default {
  name: 'form_pqrComponent',
  components: {
    verArchivos: () => import('@/components/widgets/ver_archivosComponent.vue'),
    texto2: () => import('@/components/widgets/text2Component.vue'),
    texto3: () => import('@/components/widgets/text3Component.vue')
  },
  mixins: [recListados],
  props: ['colores', 'session', 'objs', 'rules', 'items', 'loadingForm', 'gestion', 'items_archivos', 'registro'],
  data: () => ({
    loadingTipologia: false,
    items_tipologias: []
  }),
  created () {
    this.rec_listados_tipologias(this.registro.retroalimentacion_id)
    this.gestion.dias = (this.registro.retroalimentacion.accion === null) ? 0 : this.registro.retroalimentacion.accion.dias
  },
  methods: {
    refrescar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    input_retroalimentacion (obj) {
      // this.gestion.retroalimentacion_id = obj.id
      this.gestion.dias = (obj.accion === null) ? 0 : obj.accion.dias
      this.rec_listados_tipologias(obj.id)
    },
    input_replica () {
      this.gestion.argumento_replica = ''
    }
  }
}
</script>
